const tommyTranslations: any = {
  'timeout-title': {
    nl: 'Hallo, ben je er nog?',
    de: 'Hallo, sind Sie noch da?',
    fr: 'Bonjour, vous êtes toujours là ?',
    en: 'Hello, are you still there?',
    it: 'Ciao, ci sei ancora?',
    pt: 'Olá, ainda estás aí?',
    es: 'Hola, ¿sigues ahí?',
  },
  'timeout-text': {
    nl: 'Je hebt deze zuil 5 minuten niet gebruikt. Over 30 seconden breken we je boeking af. Maak een keuze:',
    de:
        'Sie haben diese Spalte seit 5 Minuten nicht mehr benutzt. Wir werden Ihre Buchung in 30 Sekunden stornieren. Treffen Sie eine Wahl:',
    fr:
        "Vous n'avez pas utilisé cette rubrique depuis 5 minutes. Nous annulerons votre réservation dans 30 secondes. Faites un choix :",
    en: "You haven't used this terminal for 5 minutes. We'll cancel your booking in 30 seconds. Make a choice:",
    it: "Non hai utilizzato questo terminale per 5 minuti. Annulleremo la tua prenotazione tra 30 secondi. Fai una scelta:",
    pt: 'Não usaste este terminal durante 5 minutos. Vamos cancelar a tua reserva em 30 segundos. Faz uma escolha:',
    es: 'No has utilizado este terminal durante 5 minutos. Cancelaremos tu reserva en 30 segundos. Haz una elección:',
  },
  'timeout-continue': {
    nl: 'Verder met boeken',
    de: 'Weiter zur Buchung',
    fr: 'Continuer avec les livres',
    en: 'Continue with booking',
    it: 'Continua con la prenotazione',
    pt: 'Continuar com a reserva',
    es: 'Continuar con la reserva',
  },
  'timeout-quit': {
    nl: 'Afbreken, naar startscherm',
    de: 'Abbrechen, zum Startbildschirm',
    fr: "Abandonner, pour démarrer l'écran",
    en: 'Return to start screen',
    it: 'Annulla, torna alla schermata iniziale',
    pt: 'Cancelar, voltar ao ecrã inicial',
    es: 'Cancelar, volver a la pantalla de inicio',
  },
  pets: {
    nl: 'Huisdieren',
    de: 'Haustiere',
    fr: 'Animaux',
    en: 'Pets',
    it: 'Animali domestici',
    pt: 'Animales domésticos',
    es: 'Animales domésticos',
  },
  'pets-title': {
    nl: 'Huisdieren',
    de: 'Haustiere',
    fr: 'Animaux',
    en: 'Pets',
    it: 'Animali domestici',
    pt: 'Animales domésticos',
    es: 'Animales domésticos',
  },
  title: {
    nl: 'Boeken',
    de: 'Buchen',
    fr: 'Reserver',
    en: 'Book',
    it: 'Prenotare',
    pt: 'Reservar',
    es: 'Reservar',
  },
  close: {
    nl: 'Sluiten',
    de: 'schließen',
    fr: 'Fermer',
    en: 'Close',
    it: 'Chiudere',
    pt: 'Fechar',
    es: 'Cerrar',
  },
  'loading-please-wait': {
    nl: 'Even geduld aub ...',
    de: 'Laden, bitte warten ...',
    fr: 'Chargement, veuillez patienter ...',
    en: 'Loading, please wait ...',
    it: 'Caricamento, attendere ...',
    pt: 'Carregando, por favor aguarde ...',
    es: 'Cargando, por favor espere ...',
  },
  'btn-next': {
    nl: 'Volgende',
    de: 'Nächste',
    fr: 'Suivant',
    en: 'Next',
    it: 'Il prossimo',
    pt: 'Próximo',
    es: 'Próximo',
  },
  'btn-complete': {
    nl: 'Boeking bevestigen',
    de: 'Buchung bestätigen',
    fr: 'Confirmation de la réservation',
    en: 'Confirm booking',
    it: 'Conferma prenotazione',
    pt: 'Confirmar reserva',
    es: 'Confirmar reserva',
  },
  'intro-booking': {
    nl: 'Start boeking',
    de: 'Buchung beginnen',
    fr: 'Commencer la réservation',
    en: 'Start booking',
    it: 'Inizia la prenotazione',
    pt: 'Iniciar reserva',
    es: 'Iniciar reserva',
  },
  'intro-reservation': {
    nl: 'Ik heb gereserveerd',
    de: 'Ich habe eine Reservierung vorgenommen.',
    fr: "J'ai fait une réservation.",
    en: 'I made a reservation.',
    it: 'Ho prenotato.',
    pt: 'Fiz uma reserva.',
    es: 'Hice una reserva.',
  },
  until: {
    nl: 'tot',
    de: 'bis',
    fr: 'bis',
    en: 'until',
    it: 'fino a',
    pt: 'até',
    es: 'hasta',
  },
  night: {
    nl: 'nacht',
    de: 'Nacht',
    fr: 'nuit',
    en: 'night',
    it: 'notte',
    pt: 'noite',
    es: 'noche',
  },
  nights: {
    nl: 'nachten',
    de: 'Nächte',
    fr: 'nuits',
    en: 'nights',
    it: 'notti',
    pt: 'noites',
    es: 'noches',
  },
  'number-of-nights': {
    nl: 'Aantal nachten',
    de: 'Anzahl der Nächte',
    fr: 'Nombre de nuits',
    en: 'Number of nights',
    it: 'Numero di notti',
    pt: 'Número de noites',
    es: 'Número de noches',
  },
  'number-of-days': {
    nl: 'Aantal dagen',
    de: 'Anzahl der Tage',
    fr: 'Nombre de jours',
    en: 'Number of days',
    it: 'Numero di giorni',
    pt: 'Número de dias',
    es: 'Número de días',
  },
  'check-details': {
    nl: 'Controle gegevens',
    de: 'Bitte prüfen Sie Ihre Buchung',
    fr: 'Données de contrôle',
    en: 'Control data',
    it: 'Controlla i tuoi dati',
    pt: 'Compruebe sus datos',
    es: 'Compruebe sus datos',
  },
  'total-amount': {
    nl: 'Totaalbedrag',
    de: 'Gesamtbetrag',
    fr: 'Montant total',
    en: 'Total amount',
    it: 'Importo totale',
    pt: 'Montante total',
    es: 'Cantidad total',
  },
  'night-available': {
    nl: 'nacht beschikbaar',
    de: 'Nacht verfügbar',
    fr: 'nuit disponible',
    en: 'night available',
    it: 'notte disponibile',
    pt: 'noite disponível',
    es: 'noche disponible',
  },
  'nights-available': {
    nl: 'nachten beschikbaar',
    de: 'Nächte verfügbar',
    fr: 'nuits disponibles',
    en: 'nights available',
    it: 'notti disponibili',
    pt: 'noites disponíveis',
    es: 'noches disponibles',
  },
  'alternative-results': {
    nl: 'Alternatieve resultaten',
    de: 'Alternative Ergebnisse',
    fr: 'Résultats alternatifs',
    en: 'Alternative results',
    it: 'Risultati alternativi',
    pt: 'Resultados alternativos',
    es: 'Resultados alternativos',
  },
  'stay-cost': {
    nl: 'Verblijfskosten',
    de: 'Kosten für Unterkunft und Verpflegung',
    fr: "Frais d'hébergement",
    en: 'Accommodation costs',
    it: 'Costi di soggiorno',
    pt: 'Custos de alojamento',
    es: 'Costos de alojamiento',
  },
  'total-cost-exclusive': {
    nl: 'Totaal excl. BTW',
    de: 'Total exkl. MwSt.',
    fr: 'Total hors TVA',
    en: 'Total excl. VAT',
    it: 'Totale escl. IVA',
    pt: 'Total excl. IVA',
    es: 'Total excl. IVA',
  },
  vat: {
    nl: 'BTW',
    de: 'MwSt',
    fr: 'TVA',
    en: 'VAT',
    it: 'IVA',
    pt: 'IVA',
    es: 'IVA',
  },
  'payment-later': {
    nl: 'Betalen kan met mobiel bankieren of op een later tijdstip bij de receptie.',
    de: 'Die Zahlung kann per Mobile Banking oder zu einem späteren Zeitpunkt an der Rezeption erfolgen.',
    fr: 'Le paiement peut être effectué par la banque mobile ou à une heure ultérieure à la réception.',
    en: 'Payment can be made by mobile banking or at a later time at reception.',
    it: 'Il pagamento può essere effettuato tramite mobile banking o in un secondo momento alla reception.',
    pt: 'O pagamento pode ser feito por mobile banking ou em um momento posterior na recepção.',
    es: 'El pago puede realizarse mediante banca móvil o en un momento posterior en recepción.',
  },
  arrival: {
    nl: 'Aankomst',
    de: 'Anreise',
    fr: 'Arrivée',
    en: 'Arrival',
    it: 'Arrivo',
    pt: 'Chegada',
    es: 'Llegada',
  },
  departure: {
    nl: 'Vertrek',
    de: 'Abreise',
    fr: 'Départ',
    en: 'Departure',
    it: 'Partenza',
    pt: 'Partida',
    es: 'Salida',
  },
  'available-results': {
    nl: 'Beschikbaar',
    de: 'Verfügbar unter',
    fr: "Disponible à l'adresse suivante",
    en: 'Available at',
    it: 'Disponibile a',
    pt: 'Disponível em',
    es: 'Disponible en',
  },
  'stay-duration': {
    nl: 'Verblijfsduur',
    de: 'Dauer des Aufenthalts',
    fr: 'Durée du séjour',
    en: 'Length of stay',
    it: 'Durata del soggiorno',
    pt: 'Duração da estadia',
    es: 'Duración de la estancia',
  },
  'license-plate-vehicle': {
    nl: 'Kenteken voertuig',
    de: 'Fahrzeug-Kennzeichen',
    fr: "Numéro d'immatriculation du véhicule",
    en: 'Vehicle registration number',
    it: 'Targa del veicolo',
    pt: 'Matrícula do veículo',
    es: 'Matrícula del vehículo',
  },
  firstname: {
    nl: 'Voornaam',
    de: 'Vorname',
    fr: 'Prénom',
    en: 'First name',
    it: 'Nome',
    pt: 'Primeiro nome',
    es: 'Nombre de pila',
  },
  infix: {
    nl: 'Tussenvoegsel',
    de: 'Einfügen',
    fr: 'Insérer',
    en: 'Middle',
    it: 'Inserimento',
    pt: 'Inserir',
    es: 'Inserción',
  },
  surname: {
    nl: 'Achternaam',
    de: 'Nachname',
    fr: 'Nom de famille',
    en: 'Last name',
    it: 'Cognome',
    pt: 'Apelido',
    es: 'Apellido',
  },
  email: {
    nl: 'E-mail adres',
    de: 'E-Mail-Adresse',
    fr: 'Adresse électronique',
    en: 'E-mail address',
    it: 'Indirizzo email',
    pt: 'Endereço de email',
    es: 'Correo electrónico',
  },
  birthdate: {
    nl: 'Geboortedatum',
    de: 'Geburtsdatum',
    fr: 'Date de naissance',
    en: 'Date of birth',
    it: 'Data di nascita',
    pt: 'Data de nascimento',
    es: 'Fecha de nacimiento',
  },
  mobile: {
    nl: 'Mobiel',
    de: 'Mobil',
    fr: 'Mobile',
    en: 'Mobile',
    it: 'Mobile',
    pt: 'Móvel',
    es: 'Móvil',
  },
  telephone: {
    nl: 'Telefoon/mobiel',
    de: 'Telefon/Mobil',
    fr: 'Téléphone/Mobile',
    en: 'Telephone/Mobile',
    it: 'Telefono/Mobile',
    pt: 'Telefone/Móvel',
    es: 'Teléfono/móvil',
  },
  'license-plate': {
    nl: 'Kenteken',
    de: 'Nummernschild',
    fr: "Plaque d'immatriculation",
    en: 'License plate',
    it: 'Targa',
    pt: 'Matrícula',
    es: 'Matrícula',
  },
  street: {
    nl: 'Straat',
    de: 'Straße',
    fr: 'Rue',
    en: 'Street',
    it: 'Strada',
    pt: 'Rua',
    es: 'Calle',
  },
  housenumber: {
    nl: 'Huisnummer',
    de: 'Hausnummer',
    fr: 'Numéro de la maison',
    en: 'House number',
    it: 'Numero civico',
    pt: 'Número da casa',
    es: 'Número de casa',
  },
  place: {
    nl: 'Plaats',
    de: 'Ort',
    fr: 'Ville natale',
    en: 'Town',
    it: 'Posizione',
    pt: 'Local',
    es: 'Lugar',
  },
  zipcode: {
    nl: 'Postcode',
    de: 'Postleitzahl',
    fr: 'Code postal',
    en: 'Zip code',
    it: 'Codice postale',
    pt: 'Código postal',
    es: 'Código postal',
  },
  country: {
    nl: 'Land',
    de: 'Land',
    fr: 'Pays',
    en: 'Country',
    it: 'Paese',
    pt: 'País',
    es: 'País',
  },
  'checkbox-no-car-or-camper': {
    nl: 'Ik kom zonder auto of camper',
    de: 'Ich komme ohne Auto oder Wohnmobil',
    fr: 'Je viens sans voiture ni camping-car',
    en: 'I come without a car or camper',
    it: 'Vengo senza auto o camper',
    pt: 'Venho sem carro ou autocaravana',
    es: 'Vengo sin coche ni autocaravana',
  },
  'results-less': {
    nl: 'Terug',
    de: 'Zurück',
    fr: 'retour',
    en: 'Back',
    it: 'Indietro',
    pt: 'De volta',
    es: 'Atrás',
  },
  'results-more': {
    nl: 'Meer',
    de: 'Mehr',
    fr: 'En savoir plus',
    en: 'More',
    it: 'Di più',
    pt: 'Mais',
    es: 'Más',
  },
  'step-back': {
    nl: 'Stap terug',
    de: 'zurück',
    fr: 'Étape précédente',
    en: 'Previous step',
    it: 'Passo indietro',
    pt: 'Passo atrás',
    es: 'Paso atrás',
  },
  'nav-quit': {
    nl: 'Stoppen en opnieuw beginnen',
    de: 'Anhalten und neu starten',
    fr: 'Arrêter et recommencer',
    en: 'Stop and start again',
    it: 'Fermare e ricominciare',
    pt: 'Parar e começar de novo',
    es: 'Detener y comenzar de nuevo',
  },
  'nav-guide': {
    nl: 'Loop je tegen een probleem aan? Meld je dan bij de receptie of bel %phone_number% als de receptie is gesloten.',
    de:
        'Haben Sie ein Problem? Dann melden Sie sich bitte an der Rezeption oder rufen Sie %phone_number% an, wenn die Rezeption geschlossen ist.',
    fr: 'Vous avez un problème ? Alors signalez-le à la réception ou appelez le %phone_number% lorsque la réception est fermée.',
    en: 'Do you have a problem? Then report it to reception or call %phone_number% when reception is closed.',
    it: 'Hai un problema? Allora segnalalo alla reception o chiama il %phone_number% quando la reception è chiusa.',
    pt: 'Tem um problema? Então reporte-o à receção ou ligue para %phone_number% quando a receção estiver fechada.',
    es: '¿Tienes un problema? Entonces repórtalo a recepción o llama al %phone_number% cuando recepción esté cerrada.',
  },
  'intro-text-1': {
    nl: `
      <h2>Boek een kampeer- of camperplaats</h2>
      <p>Je kunt hier een kampeer- of camperplaats boeken en direct inchecken. Betalen kan met mobiel bankieren of op een later tijdstip bij de receptie.</p>
      <p>Uitgebreide informatie over onze kampeer- en camper plaatsen vind je de receptie of check %website_url%</p>
    `,
    de: `
      <h2>Campingplatz oder Wohnmobilstellplatz buchen</h2>
      <p>Sie können hier einen Camping- oder Wohnmobilstellplatz buchen und direkt einchecken. Sie können mit Mobile Banking oder zu einem späteren Zeitpunkt an der Rezeption bezahlen.</p>
      <p>Umfassende Informationen über unsere Camping- und Wohnmobilstellplätze finden Sie an der Rezeption oder unter %website_url%</p>
    `,
    fr: `
      <h2>Réserver un emplacement de camping ou de camping-car</h2>
      <p>Vous pouvez réserver un emplacement de camping ou de camping-car ici et vous enregistrer directement. Vous pouvez payer avec le service bancaire mobile ou plus tard à la réception.</p>
      <p>Vous trouverez des informations complètes sur nos emplacements de camping et de caravane à la réception ou sur le site %website_url%.</p>
    `,
    en: `
      <h2>Book a camping- or motorhome pitch</h2>
      <p>You can book a camping- or motorhome pitch here and check in directly. You can pay with mobile banking or at a later time at the reception.</p>
      <p>Comprehensive information about our camping- and camper pitches can be found at the reception or check %website_url%</p>
    `,
    it: `
        <h2>Prenota un posto campeggio o camper</h2>
        <p>Puoi prenotare un posto campeggio o camper qui e fare il check-in direttamente. Puoi pagare con mobile banking o in un secondo momento alla reception.</p>
        <p>Troverai informazioni dettagliate sui nostri posti campeggio e camper alla reception o controlla %website_url%</p>
        `,
    pt: `
        <h2>Reserve um lugar de acampamento ou de autocaravana</h2>
        <p>Pode reservar um lugar de acampamento ou de autocaravana aqui e fazer o check-in diretamente. Pode pagar com mobile banking ou mais tarde na receção.</p>
        <p>Encontrará informações detalhadas sobre os nossos lugares de acampamento e de autocaravana na receção ou verifique %website_url%</p>
        `,
    es: `
        <h2>Reserve un lugar de camping o de autocaravana</h2>
        <p>Puede reservar un lugar de camping o de autocaravana aquí y registrarse directamente. Puede pagar con banca móvil o más tarde en recepción.</p>
        <p>Encontrará información detallada sobre nuestros lugares de camping y de autocaravana en recepción o consulte %website_url%</p>
        `,
  },
  'intro-text-2': {
    nl: `
      <h3>Accommodatie boeken</h3>
      <p>Boeken van accommodaties kan uitsluitend bij de receptie tijdens openingstijden of online via %website_url%.</p>
    `,
    de: `
    <h3> Unterkunft buchen</h3>
    <p>Die Buchung einer Unterkunft ist nur an der Rezeption während der Öffnungszeiten oder online unter %website_url% möglich.</p>
  `,
    fr: `
    <h3> Réserver un logement</h3>
    <p>La réservation d'un logement est possible uniquement à la réception pendant les heures d'ouverture ou en ligne sur %website_url%.</p>
  `,
    en: `
    <h3> Booking accommodation</h3>
    <p>Booking accommodation is only possible at the reception during opening hours or online at %website_url%.</p>
  `,
    it: `
    <h3> Prenotare un alloggio</h3>
    <p>La prenotazione di un alloggio è possibile solo presso la reception durante l'orario di apertura o online su %website_url%.</p>
    `,
    pt: `
    <h3> Reservar alojamento</h3>
    <p>A reserva de alojamento só é possível na receção durante o horário de funcionamento ou online em %website_url%.</p>
    `,
    es: `
    <h3> Reservar alojamiento</h3>
    <p>La reserva de alojamiento solo es posible en recepción durante el horario de apertura o en línea en %website_url%.</p>
    `,
  },
  'reserved-text': {
    nl: `
      <p>Todo: ik heb reeds gereserveerd tekst.</p>
    `,
    de: `
    <p>Todo: Ich habe bereits Text gebucht.</p>
  `,
    fr: `
    <p>Todo : J'ai déjà réservé le texte.</p>
  `,
    en: `
    <p>Todo: I've already booked text.</p>
  `,
    it: `
    <p>Todo: ho già prenotato il testo.</p>
    `,
    pt: `
    <p>Todo: Já reservei o texto.</p>
    `,
    es: `
    <p>Todo: Ya he reservado el texto.</p>
    `,
  },
  'accommodation-text-1': {
    nl: '',
    de: '',
    fr: '',
    en: '',
    it: '',
    pt: '',
    es: '',
  },
  'travelgroup-title': {
    nl: 'Reisgezelschap',
    de: 'Reisegesellschaft',
    fr: 'Compagnie de voyage',
    en: 'Travel company',
    it: 'Gruppo di viaggiatori',
    pt: 'Grupo itinerante',
    es: 'Grupo itinerante',
  },
  'floorplan-title': {
    nl: 'Kies uw locatie',
    de: 'Wählen Sie Ihren Standort',
    fr: 'Choisissez votre lieu',
    en: 'Choose your location',
    it: 'Scegli la tua posizione',
    pt: 'Escolha a sua localização',
    es: 'Elige tu ubicación',
  },
  'floorplan-chosen-location': {
    nl: 'Geselecteerde locatie',
    de: 'Ausgewählter Standort',
    fr: 'Lieu sélectionné',
    en: 'Selected location',
    it: 'Posizione selezionata',
    pt: 'Localização selecionada',
    es: 'Ubicación seleccionada',
  },
  'floorplan-choose-location': {
    nl: 'Selecteer een locatie op de kaart door op (afbeelding druppel) te klikken.',
    de: 'Wählen Sie Ihren Platz auf dem Parkplan, indem Sie auf dem Markierungssymbol klicken',
    fr: "Sélectionnez un emplacement sur le plan en cliquant sur (l'image du marqueur.) ",
    en: 'Select a location on the map by clicking the (marker icon).',
    it: 'Seleziona una posizione sulla mappa facendo clic sull\'icona del marcatore.',
    pt: 'Selecione uma localização no mapa clicando no ícone do marcador.',
    es: 'Seleccione una ubicación en el mapa haciendo clic en el icono del marcador.',
  },
  'travelgroup-too-many': {
    nl: '<p>Per accommodatie zijn maximaal %total_max_persons% personen toegestaan.',
    de: '<p>Per Unterkunft sind maximal %total_max_persons% Personen erlaubt.',
    fr: '<p>Par logement, un maximum de %total_max_persons% personnes sont autorisées.',
    en: '<p>Per accommodation a maximum of %total_max_persons% persons are allowed.',
    it: '<p>Per alloggio sono ammesse un massimo di %total_max_persons% persone.',
    pt: '<p>Por alojamento, um máximo de %total_max_persons% pessoas são permitidas.',
    es: '<p>Por alojamiento se permiten un máximo de %total_max_persons% personas.',
  },
  'finish-text-1': {
    nl: `
        <h2>Boeking succesvol afgerond</h2>
        <p>De boeking is afgerond en je bent ingechecked op locatie <strong class="location">%accommodation_name% (%location_name%)</strong>. Je ontvangt binnen enkele minuten een bevestiging per mail. Betaal voor %payment_due_date% bij de receptie of met mobiel bankieren.</p>
        <p>Je ontvangt binnen enkele minuten een bevestiging per mail met betaallink. Je kunt ook betalen op de receptie.</p>
    `,
    de: `
        <h2>Buchung erfolgreich abgeschlossen</h2>
        <p>Die Buchung ist abgeschlossen und Sie haben am Standort <strong class="location">%accommodation_name% (%location_name%)</strong> eingecheckt. Sie erhalten innerhalb weniger Minuten eine Bestätigung per E-Mail. Bezahlen Sie %payment_due_date% an der Rezeption oder mit Mobile Banking.</p>
        <p>Fahren Sie zur Schranke. Es öffnet sich automatisch.</p>
    `,
    fr: `
        <h2>Réservation effectuée avec succès</h2>
        <p>La réservation a été effectuée et vous vous êtes enregistré à l'emplacement <strong class="location">%accommodation_name% (%location_name%)</strong>. Vous recevrez une confirmation par courrier électronique dans les minutes qui suivent. Payez le %payment_due_date% à la réception ou avec le mobile banking.</p>
        <p>Venir à la barrière. Il s'ouvre automatiquement.</p>
    `,
    en: `
        <h2>Booking successfully completed</h2>
        <p>The booking has been completed and you have checked-in at location <strong class="location">%accommodation_name% (%location_name%)</strong>. You will receive a confirmation by email within a few minutes. Pay on %payment_due_date% at the reception or with mobile banking.</p>
        <p>Drive to the barrier. It opens automatically.</p>
    `,
    it: `
        <h2>Prenotazione completata con successo</h2>
        <p>La prenotazione è stata completata e ti sei registrato presso la posizione <strong class="location">%accommodation_name% (%location_name%)</strong>. Riceverai una conferma via e-mail entro pochi minuti. Paga entro %payment_due_date% alla reception o con il mobile banking.</p>
        <p>Guidare alla barriera. Si apre automaticamente.</p>
    `,
    pt: `
        <h2>Reserva concluída com sucesso</h2>
        <p>A reserva foi concluída e fez o check-in na localização <strong class="location">%accommodation_name% (%location_name%)</strong>. Receberá uma confirmação por e-mail dentro de alguns minutos. Pague em %payment_due_date% na receção ou com o mobile banking.</p>
        <p>Dirija-se à barreira. Abre automaticamente.</p>
    `,
    es: `
        <h2>Reserva completada con éxito</h2>
        <p>La reserva se ha completado y se ha registrado en la ubicación <strong class="location">%accommodation_name% (%location_name%)</strong>. Recibirá una confirmación por correo electrónico en unos minutos. Pague en %payment_due_date% en recepción o con banca móvil.</p>
        <p>Conducir a la barrera. Se abre automáticamente.</p>
    `,
  },
  'finish-text-2': {
    nl: '<h1 class="xl">Jouw vakantie op %park_name% is begonnen!</h1>',
    de: '<h1 class="xl">Ihr Urlaub auf %park_name% hat begonnen!</h1>',
    fr: '<h1 class="xl">Vos vacances sur %park_name% ont commencé!</h1>',
    en: '<h1 class="xl">Your holiday on %park_name% has begun!</h1>',
    it: '<h1 class="xl">La tua vacanza a %park_name% è iniziata!</h1>',
    pt: '<h1 class="xl">As suas férias em %park_name% começaram!</h1>',
    es: '<h1 class="xl">¡Tus vacaciones en %park_name% han comenzado!</h1>',
  },
  'finish-pin-text-1': {
    nl: ``,
    de: ``,
    fr: ``,
    en: ``,
    it: ``,
    pt: ``,
    es: ``,
  },
  'finish-pin-text-2': {
    nl: '',
    de: '',
    fr: '',
    en: '',
    it: '',
    pt: '',
    es: '',
  },
  'required-field-error': {
    nl: 'Vul je %field% in',
    de: 'Füllen Sie Ihr %field%',
    fr: 'Remplissez votre %field%.',
    en: 'Fill in your %field%',
    it: 'Compila il tuo %field%',
    pt: 'Preencha o seu %field%',
    es: 'Rellene su %field%',
  },
  'duration-title': {
    nl: 'Verblijfsduur',
    de: 'Dauer des Aufenthalts',
    fr: 'Durée du séjour',
    en: 'Length of stay',
    it: 'Durata del soggiorno',
    pt: 'Duraçión',
    es: 'Duración',
  },
  'accommodation-title': {
    nl: 'Kies een type',
    de: 'Wählen Sie einen Typ',
    fr: 'Choisissez un type',
    en: 'Choose a type',
    it: 'Scegli un tipo',
    pt: 'Escolha um tipo',
    es: 'Elige un tipo',
  },
  'extras-title': {
    nl: "Extra's",
    de: 'Extras',
    fr: 'Extras',
    en: 'Extras',
    it: 'Extra',
    pt: 'Extras',
    es: 'Extras',
  },
  'extras-other': {
    nl: 'Overige',
    de: 'Andere',
    fr: 'Autres',
    en: 'Additional',
    it: 'Altro',
    pt: 'Outros',
    es: 'Otro',
  },
  'extras-couponcode': {
    nl: 'Actiecode',
    de: 'Angebots-Code',
    fr: "Code promo",
    en: 'Offer code',
    it: 'Codice offerta',
    pt: 'Código de oferta',
    es: 'Código de oferta',
  },
  'extras-couponcode-required': {
    nl: '(verplicht)',
    de: '(obligatorisch)',
    fr: '(obligatoire)',
    en: '(mandatory)',
    it: '(obbligatorio)',
    pt: '(obrigatório)',
    es: '(obligatorio)',
  },
  'receipt-title': {
    nl: 'Boekingsoverzicht',
    de: 'Buchungsübersicht',
    fr: 'Aperçu des réservations',
    en: 'Booking overview',
    it: 'Panoramica delle prenotazioni',
    pt: 'Resumo das reservas',
    es: 'Resumen de reservas',
  },
  'confirm-title': {
    nl: 'Bevestigen',
    de: 'Bestätigen',
    fr: 'Confirmer',
    en: 'Confirm',
    it: 'Confermare',
    pt: 'Confirmar',
    es: 'Confirmar',
  },
  'personaldata-title': {
    nl: 'Persoonsgegevens',
    de: 'Persönliche Daten',
    fr: 'Données à caractère personnel',
    en: 'Personal information',
    it: 'Dati personali',
    pt: 'Dados pessoais',
    es: 'Datos personales',
  },
  'address-title': {
    nl: 'Adresgegevens',
    de: 'Angaben zur Adresse',
    fr: 'Informations sur les adresses',
    en: 'Address details',
    it: 'Informazioni sull\'indirizzo',
    pt: 'Detalhes do endereço',
    es: 'Detalles de la dirección',
  },
  salution: {
    nl: 'Aanhef',
    de: 'Anrede',
    fr: 'Salutation',
    en: 'Salutation',
    it: 'Saluto',
    pt: 'Saudação',
    es: 'Saludo',
  },
  'salution-malefemale': {
    nl: 'Meneer / Mevrouw',
    de: 'Herr / Frau',
    fr: 'Monsieur / Madame',
    en: 'Mr / Mrs',
    it: 'Signor / Signora',
    pt: 'Senhor / Senhora',
    es: 'Señor / Señora',
  },
  'salution-male': {
    nl: 'Meneer',
    de: 'Herr',
    fr: 'Monsieur',
    en: 'Mister',
    it: 'Signor',
    pt: 'Senhor',
    es: 'Señor',
  },
  'salution-female': {
    nl: 'Mevrouw',
    de: 'Frau',
    fr: 'Madame',
    en: 'Mrs',
    it: 'Signora',
    pt: 'Senhora',
    es: 'Señora',
  },
  'salution-family': {
    nl: 'Familie',
    de: 'Familie',
    fr: 'Famille',
    en: 'Family',
    it: 'Famiglia',
    pt: 'Família',
    es: 'Familia',
  },
  'required-notice': {
    nl: 'Niet alle velden zijn goed ingevuld.',
    de: 'Nicht alle Felder sind korrekt ausgefüllt.',
    fr: 'Tous les champs ne sont pas remplis correctement.',
    en: 'Not all fields are filled in correctly.',
    it: 'Non tutti i campi sono stati compilati correttamente.',
    pt: 'Nem todos os campos estão preenchidos corretamente.',
    es: 'No todos los campos están rellenados correctamente.',
  },
  'pin-title': {
    nl: 'Pinnen',
    de: 'Zahlen',
    fr: 'Payer',
    en: 'Pay',
    it: 'Pagare',
    pt: 'Pagar',
    es: 'Pagar',
  },
  'pin-description': {
    nl: `
      <p>Binnen enkele seconden kunt u uw betaling op de betaalautomaat voldoen met uw pinpas</p>
      <p>U heeft 30 seconden om de betaling te voldoen, lukt dit niet?</p>
      <p>Wij versturen u nog altijd een betaallink per mail, zodat u alsnog de betaling kan voldoen</p>
    `,
    de: `
      <p>Innerhalb von Sekunden können Sie Ihre Zahlung am Zahlungsterminal mit Ihrer Debitkarte vornehmen</p>
      <p>Sie haben 30 Sekunden, um die Zahlung zu tätigen, hat es nicht geklappt? </p>
      <p>Wir senden Ihnen nach wie vor einen Zahlungslink per E-Mail zu, so dass Sie die Zahlung noch vornehmen können</p>.
    `,
    fr: `
      <p>En quelques secondes, vous pouvez effectuer votre paiement au terminal de paiement avec votre carte de débit</p>
      <p>Vous avez 30 secondes pour effectuer le paiement, ça n'a pas marché ? </p>
      <p>Nous vous envoyons toujours un lien de paiement par e-mail, de sorte que vous pouvez toujours effectuer le paiement</p>.
    `,
    en: `
      <p>Within seconds you can make your payment at the POS terminal with your debit card</p>
      <p>You have 30 seconds to make the payment, it didn't work? </p>
      <p>We still send you a payment link by email, so that you can still make the payment</p>
    `,
    it: `
      <p>In pochi secondi puoi effettuare il pagamento al terminale POS con la tua carta di debito</p>
      <p>Hai 30 secondi per effettuare il pagamento, non ha funzionato? </p>
      <p>Ti inviamo comunque un link di pagamento via e-mail, in modo che tu possa effettuare il pagamento</p>.
    `,
    pt: `
      <p>Em poucos segundos, pode efetuar o pagamento no terminal POS com o seu cartão de débito</p>
      <p>Tem 30 segundos para efetuar o pagamento, não funcionou? </p>
      <p>Enviamos-lhe ainda um link de pagamento por e-mail, para que possa efetuar o pagamento</p>.
    `,
    es: `
      <p>En unos segundos puede realizar el pago en el terminal POS con su tarjeta de débito</p>
      <p>Tiene 30 segundos para realizar el pago, ¿no funcionó? </p>
      <p>Le enviamos un enlace de pago por correo electrónico, para que pueda realizar el pago</p>.
    `,
  },
  'finish-button': {
    nl: 'Terug naar startscherm',
    de: 'Zurück zum Startbildschirm',
    fr: 'Retour à l\'écran d\'accueil',
    en: 'Back to home',
    it: 'Torna alla schermata iniziale',
    pt: 'Voltar ao início',
    es: 'Volver a la pantalla de inicio',
  },
  'pin-button': {
    nl: 'Voltooi boeking',
    de: 'Fertigstellen',
    fr: 'Terminer',
    en: 'Finish',
    it: 'Fine',
    pt: 'Terminar',
    es: 'Terminar',
  },
  'pin-failed-retry': {
    nl: 'De betaling is helaas mislukt, klik op onderstaande knop om het opnieuw te proberen',
    de: 'Leider ist die Zahlung fehlgeschlagen. Klicken Sie auf die Schaltfläche unten, um es erneut zu versuchen',
    fr: 'Malheureusement, le paiement a échoué, cliquez sur le bouton ci-dessous pour réessayer',
    en: 'Unfortunately the payment failed, click the button below to try again',
    it: 'Purtroppo il pagamento non è riuscito, fare clic sul pulsante qui sotto per riprovare',
    pt: 'Infelizmente o pagamento falhou, clique no botão abaixo para tentar novamente',
    es: 'Desafortunadamente el pago falló, haga clic en el botón de abajo para intentarlo de nuevo',
  },
  'pin-paid': {
    nl: 'U ontvangt een bevestiging via de e-mail',
    de: 'Sie erhalten eine Bestätigung per E-Mail',
    fr: 'Vous recevrez une confirmation par e-mail',
    en: 'You will receive a confirmation by e-mail',
    it: 'Riceverai una conferma via e-mail',
    pt: 'Receberá uma confirmação por e-mail',
    es: 'Recibirá una confirmación por correo electrónico',
  },
  'pin-failed': {
    nl: 'De betaling is helaas niet gelukt',
    de: 'Leider war die Zahlung nicht erfolgreich',
    fr: 'Malheureusement, le paiement n\'a pas abouti',
    en: 'Unfortunately the payment was not successful',
    it: 'Purtroppo il pagamento non è andato a buon fine',
    pt: 'Infelizmente o pagamento não foi bem-sucedido',
    es: 'Desafortunadamente el pago no fue exitoso',
  },
  'pin-retry-3': {
    nl: 'Klik hier om de betaling af te ronden',
    de: 'Klicken Sie hier, um die Zahlung abzuschließen',
    fr: 'Cliquez ici pour compléter le paiement',
    en: 'Click here to complete the payment',
    it: 'Fare clic qui per completare il pagamento',
    pt: 'Clique aqui para concluir o pagamento',
    es: 'Haga clic aquí para completar el pago',
  },
  'pin-retry-2': {
    nl: 'Klik hier om de betaling af te ronden',
    de: 'Klicken Sie hier, um die Zahlung abzuschließen',
    fr: 'Cliquez ici pour compléter le paiement',
    en: 'Click here to complete the payment',
    it: 'Fare clic qui per completare il pagamento',
    pt: 'Clique aqui para concluir o pagamento',
    es: 'Haga clic aquí para completar el pago',
  },
  'pin-retry-1': {
    nl: 'Klik hier om de betaling af te ronden',
    de: 'Klicken Sie hier, um die Zahlung abzuschließen',
    fr: 'Cliquez ici pour compléter le paiement',
    en: 'Click here to complete the payment',
    it: 'Fare clic qui per completare il pagamento',
    pt: 'Clique aqui para concluir o pagamento',
    es: 'Haga clic aquí para completar el pago',
  },
  'reductionpass-title': {
    nl: 'Voordeelkaarten',
    de: 'Rabattkarten ',
    en: 'Discount cards',
    fr: 'Cartes de réduction',
    it: 'Carte sconto',
    pt: 'Cartões de desconto',
    es: 'Tarjetas de descuento',
  },
  'reductionpass-none': {
    nl: 'Geen voordeelkaart',
    de: 'Keine Rabattkarte',
    en: 'No discount card',
    fr: 'Pas de carte de réduction',
    it: 'Nessuna carta sconto',
    pt: 'Sem cartão de desconto',
    es: 'Sin tarjeta de descuento',
  },
  'price-no-reductionpass': {
    nl: 'Prijs zonder voordeelkaart',
    de: 'Preis ohne Rabattkarte',
    fr: 'Prix sans carte de réduction',
    en: 'Price without discount card',
    it: 'Prezzo senza carta sconto',
    pt: 'Preço sem cartão de desconto',
    es: 'Precio sin tarjeta de descuento',
  },
  before: {
    nl: 'voor',
    de: 'für',
    fr: 'pour',
    en: 'for',
    it: 'per',
    pt: 'para',
    es: 'para',
  },
  after: {
    nl: 'na',
    de: 'nach',
    fr: 'après',
    en: 'after',
    it: 'dopo',
    pt: 'depois',
    es: 'después',
  },
  'available-results-none': {
    nl: 'Volgeboekt',
    de: 'Ausgebucht',
    fr: 'Entièrement réservé',
    en: 'Fully booked',
    it: 'Completamente prenotato',
    pt: 'Totalmente reservado',
    es: 'Completamente reservado',
  },
  'available-results-yes': {
    nl: 'Ja er is beschikbaarheid',
    de: 'Ja, es gibt Verfügbarkeit',
    fr: 'Oui, il y a de la disponibilité',
    en: 'Yes there is availability',
    it: 'Sì, c\'è disponibilità',
    pt: 'Sim, há disponibilidade',
    es: 'Sí, hay disponibilidad',
  },
  'intro-text-1-alt': {
    nl: 'intro-text-1-alt',
    de: 'intro-text-1-alt',
    fr: 'intro-text-1-alt',
    en: 'intro-text-1-alt',
    it: 'intro-text-1-alt',
    pt: 'intro-text-1-alt',
    es: 'intro-text-1-alt',
  },
  'intro-text-2-alt': {
    nl: 'intro-text-2-alt',
    de: 'intro-text-2-alt',
    fr: 'intro-text-2-alt',
    en: 'intro-text-2-alt',
    it: 'intro-text-2-alt',
    pt: 'intro-text-2-alt',
    es: 'intro-text-2-alt',
  },
  'signup-reservation-number': {
    nl: `Reserveringsnummer`,
    de: `Reservierungsnummer`,
    fr: `Numéro de réservation`,
    en: `Reservation number`,
    it: `Numero di prenotazione`,
    pt: `Número de reserva`,
    es: `Número de reserva`,
  },
  'signup-reservation-button': {
    nl: `Aanmelden`,
    de: `Anmeldung`,
    fr: `Inscription`,
    en: `Sign up`,
    it: `Iscriviti`,
    pt: `Inscrever-se`,
    es: `Inscribirse`,
  },
  'timetable-disabled': {
    nl: 'Welcome to %park_name%',
    de: 'Willkommen bei %park_name%',
    fr: 'Bienvenue à %park_name%',
    en: 'Welcome to %park_name%',
    it: 'Benvenuti a %park_name%',
    pt: 'Bem-vindo ao %park_name%',
    es: 'Bienvenido a %park_name%',
  },
  'timetable-disabled-message': {
    nl: 'This check-in kiosk is only available between 8:05 PM and 10:45 PM, outside reception opening hours. During reception hours you can check in at our reception, we are happy to help you. Our reception is open every day between 9:00 AM and 8:00 PM.',
    de: 'Dieser Check-in-Kiosk ist nur zwischen 20:05 und 22:45 Uhr verfügbar, außerhalb der Rezeptionszeiten. Während der Rezeptionszeiten können Sie an unserer Rezeption einchecken, wir sind Ihnen gerne behilflich. Unsere Rezeption ist jeden Tag zwischen 09:00 und 20:00 Uhr geöffnet.',
    fr: 'Ce kiosque d\'enregistrement n\'est disponible qu\'entre 20 h 05 et 22 h 45, en dehors des heures de réception. Pendant les heures de réception, vous pouvez vous enregistrer à notre réception, nous serons heureux de vous aider. Notre réception est ouverte tous les jours entre 09h00 et 20h00.',
    en: 'This check-in kiosk is only available between 8:05 PM and 10:45 PM, outside reception opening hours. During reception hours you can check in at our reception, we are happy to help you. Our reception is open every day between 9:00 AM and 8:00 PM.',
    it: 'Questo chiosco per il check-in è disponibile solo tra le 20:05 e le 22:45, al di fuori degli orari di ricevimento. Durante l\'orario di ricevimento è possibile effettuare il check-in presso la nostra reception, che sarà lieta di aiutarvi. La nostra reception è aperta tutti i giorni dalle 09:00 alle 20:00.',
    pt: 'Este quiosque de check-in só está disponível entre as 20:05 e as 22:45, fora do horário da receção. Durante o horário da receção, pode efetuar o check-in na nossa receção, teremos todo o gosto em ajudá-lo. A nossa receção está aberta todos os dias entre as 09:00 e as 20:00.',
    es: 'Este quiosco de facturación sólo está disponible entre las 20:05 y las 22:45, fuera del horario de recepción. Durante el horario de recepción puede hacer el check-in en nuestra recepción, estaremos encantados de ayudarle. Nuestra recepción está abierta todos los días entre las 09:00 y las 20:00.',
  },
  'signup-reservation-success': {
    nl: 'Aanmelden voltooid',
    de: 'Anmeldung abgeschlossen',
    fr: 'Inscription terminée',
    en: 'Sign up completed',
    it: 'Iscriviti completato',
    pt: 'Inscreva-se concluído',
    es: 'Inscripción completada',
  },
  'retry': {
    nl: 'Retry',
    de: 'Wiederholen',
    fr: 'Réessayer',
    en: 'Retry',
    it: 'Riprova',
    pt: 'Tentar novamente',
    es: 'Reintentar',
  },
  'yes-no-min': {
    nl: 'Verwijderen',
    de: 'Entfernen',
    fr: 'Supprimer',
    en: 'Remove',
    it: 'Rimuovere',
    pt: 'Remover',
    es: 'Eliminar',
  },
  'yes-no-plus': {
    nl: 'Toevoegen',
    de: 'Hinzufügen',
    fr: 'Ajouter',
    en: 'Add',
    it: 'Aggiungi',
    pt: 'Adicionar',
    es: 'Añadir',
  },
  'month-01': {
    nl: 'Januari',
    de: 'Januar',
    fr: 'Janvier',
    en: 'January',
    it: 'Gennaio',
    pt: 'Janeiro',
    es: 'Enero',
  },
  'month-02': {
    nl: 'Februari',
    de: 'Februar',
    fr: 'Février',
    en: 'February',
    it: 'Febbraio',
    pt: 'Fevereiro',
    es: 'Febrero',
  },
  'month-03': {
    nl: 'Maart',
    de: 'März',
    fr: 'Mars',
    en: 'March',
    it: 'Marzo',
    pt: 'Março',
    es: 'Marzo',
  },
  'month-04': {
    nl: 'April',
    de: 'April',
    fr: 'Avril',
    en: 'April',
    it: 'Aprile',
    pt: 'Abril',
    es: 'Abril',
  },
  'month-05': {
    nl: 'Mei',
    de: 'Mai',
    fr: 'Mai',
    en: 'May',
    it: 'Maggio',
    pt: 'Maio',
    es: 'Mayo',
  },
  'month-06': {
    nl: 'Juni',
    de: 'Juni',
    fr: 'Juin',
    en: 'June',
    it: 'Giugno',
    pt: 'Junho',
    es: 'Junio',
  },
  'month-07': {
    nl: 'Juli',
    de: 'Juli',
    fr: 'Juillet',
    en: 'July',
    it: 'Luglio',
    pt: 'Julho',
    es: 'Julio',
  },
  'month-08': {
    nl: 'Augustus',
    de: 'August',
    fr: 'Août',
    en: 'August',
    it: 'Agosto',
    pt: 'Agosto',
    es: 'Agosto',
  },
  'month-09': {
    nl: 'September',
    de: 'September',
    fr: 'Septembre',
    en: 'September',
    it: 'Settembre',
    pt: 'Setembro',
    es: 'Septiembre',
  },
  'month-10': {
    nl: 'Oktober',
    de: 'Oktober',
    fr: 'Octobre',
    en: 'October',
    it: 'Ottobre',
    pt: 'Outubro',
    es: 'Octubre',
  },
  'month-11': {
    nl: 'November',
    de: 'November',
    fr: 'Novembre',
    en: 'November',
    it: 'Novembre',
    pt: 'Novembro',
    es: 'Noviembre',
  },
  'month-12': {
    nl: 'December',
    de: 'Dezember',
    fr: 'Décembre',
    en: 'December',
    it: 'Dicembre',
    pt: 'Dezembro',
    es: 'Diciembre',
  },
};

module.exports = tommyTranslations;
