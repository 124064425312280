const noTranslation: any = {
  nl: '',
  de: '',
  fr: '',
  en: '',
};

const overrideTranslations: any = {
  vreehorst: {
    before: {
      nl: 'voor',
      de: 'vor',
      fr: 'pour',
      en: 'before',
    },
    after: {
      nl: 'na',
      de: 'nach',
      fr: 'après',
      en: 'after',
    },
    'confirm-title': {
      ...noTranslation,
    },
    // no nav-guide for Vreehorst
    'nav-guide': {
      ...noTranslation,
    },
    // no intro-text-2 for vreehorst
    'intro-text-2': {
      ...noTranslation,
    },
    'payment-later': {
      ...noTranslation,
    },
    'pin-title': {
      ...noTranslation,
    },
    'pin-description': {
      ...noTranslation,
    },
    'finish-text-1': {
      nl: `
        <h2>Boeking succesvol afgerond</h2>
        <p>Uw locatie <strong class="location">%accommodation_name% (%location_name%)</strong> is beschikbaar voor u vanaf %arrival_time%.</p>
        <p>Totaalbedrag: %total_receipt_price_formatted%.</p>
        <p>U ontvangt binnen enkele minuten een bevestiging per mail met betaallink.</p>
        <p>
            Locatie: %accommodation_name% (%location_name%)<br />
            Aankomst: %arrival_date% na %arrival_time%<br />
            Vertrek: %departure_date% voor %departure_time%
        </p>
        <p>U kunt door de slagboom met uw kenteken: %license_plate%</p>
        <p>Het Camperpark is bereikbaar via de Vreehorstweg.</p>
    `,
      de: `
        <h2>Buchung erfolgreich abgeschlossen</h2>
        <p>Ihr Platz <strong class="location">%accommodation_name% (%location_name%)</strong> steht Ihnen ab %arrival_time% zur Verfügung.</p>
        <p>Gesamtbetrag: %total_receipt_price_formatted%.</p>
        <p>Sie erhalten innerhalb weniger Minuten eine Bestätigung per E-Mail mit einem Zahlungslink.</p>
        <p>
            Platznummer: %accommodation_name% (%location_name%)<br />
            Anreise: %arrival_date% nach %arrival_time%<br />
            Abreise: %departure_date% vor %departure_time%
        </p>
        <p>Sie können mit Ihrem Nummernschild durch die Schranke fahren: %license_plate%</p>
        <p>Der Camperpark ist über den Vreehorstweg erreichbar.</p>
    `,
      en: '',
      fr: '',
    },
    'finish-text-2': {
      nl: '<h1 class="xl">Uw vakantie op %park_name% is begonnen!</h1>',
      de: '<h1 class="xl">Ihr Urlaub auf %park_name% hat begonnen!</h1>',
      fr: '<h1 class="xl">Vos vacances sur %park_name% ont commencé!</h1>',
      en: '<h1 class="xl">Your holiday on %park_name% has begun!</h1>',
    },
    'intro-text-1': {
      nl: `
        <h2>Boek een kampeer- of camperplaats</h2>
        <p>Boek een plaats en check direct in. U betaald vooraf de nachten die u boekt. Bij eerder vertrek vindt geen restitutie plaats.</p>
        <p>De slagboom werkt op kentekenherkenning.</p>
      `,
      de: `
        <h2>Campingplatz oder Wohnmobilstellplatz buchen</h2>
        <p>Reservieren Sie einen Platz und checken Sie sofort ein. Sie zahlen im Voraus für die von Ihnen gebuchten Nächte. Bei vorzeitiger Abreise gibt es keine Rückerstattung.</p>
        <p>Die Schranke funktioniert bei der Nummernschilderkennung.</p>`,
      en: `
        <h2>Book a camping pitch or motorhome pitch</h2>
        <p>Book a seat and check in immediately. You pay in advance for the nights you book. In case of early departure there will be no refund.</p>
        <p>The barrier works on license plate recognition.</p>
      `,
      // todo: translate
      fr: '',
    },
  },
  vreehorstcp: {
    before: {
      nl: 'voor',
      de: 'vor',
      fr: 'pour',
      en: 'for',
    },
    after: {
      nl: 'na',
      de: 'nach',
      fr: 'après',
      en: 'after',
    },
    'confirm-title': {
      ...noTranslation,
    },
    // no nav-guide for Vreehorst
    'nav-guide': {
      ...noTranslation,
    },
    // no intro-text-2 for vreehorst
    'intro-text-2': {
      ...noTranslation,
    },
    'payment-later': {
      ...noTranslation,
    },
    'pin-title': {
      ...noTranslation,
    },
    'pin-description': {
      ...noTranslation,
    },
    'finish-text-1': {
      nl: `
        <h2>Boeking succesvol afgerond</h2>
        <p>Uw locatie <strong class="location">%accommodation_name% (%location_name%)</strong> is beschikbaar voor u vanaf %arrival_time%.</p>
        <p>Totaalbedrag: %total_receipt_price_formatted%.</p>
        <p>U heeft 30 seconden om de betaling te voldoen via de betaalautomaat.</p>
        <p>Is de betaling niet gelukt? U ontvangt binnen enkele minuten een bevestiging per mail met betaallink.</p>
        <p>
            Locatie: %accommodation_name% (%location_name%)<br />
            Aankomst: %arrival_date% na %arrival_time%<br />
            Vertrek: %departure_date% voor %departure_time%
        </p>
        <p>U kunt door de slagboom met uw kenteken: %license_plate%</p>
    `,
      de: `
        <h2>Buchung erfolgreich abgeschlossen</h2>
        <p>Ihr Platz <strong class="location">%accommodation_name% (%location_name%)</strong> steht Ihnen ab %arrival_time% zur Verfügung.</p>
        <p>Gesamtbetrag: %total_receipt_price_formatted%.</p>
        <p>Sie haben 30 Sekunden Zeit, um die Zahlung zu leisten.</p>
        <p>Ist die Zahlung nicht erfolgreich? Sie erhalten dann innerhalb weniger Minuten eine Bestätigung per E-Mail mit einem Zahlungslink.</p>
        <p>
            Platznummer: %accommodation_name% (%location_name%)<br />
            Anreise: %arrival_date% nach %arrival_time%<br />
            Abreise: %departure_date% vor %departure_time%
        </p>
        <p>Sie können mit Ihrem Nummernschild durch die Schranke fahren: %license_plate%</p>
    `,
      en: '',
      fr: '',
    },
    'finish-text-2': {
      nl: '<h1 class="xl">Uw vakantie op %park_name% is begonnen!</h1>',
      de: '<h1 class="xl">Ihr Urlaub auf %park_name% hat begonnen!</h1>',
      fr: '<h1 class="xl">Vos vacances sur %park_name% ont commencé!</h1>',
      en: '<h1 class="xl">Your holiday on %park_name% has begun!</h1>',
    },
    'intro-text-1': {
      nl: `
        <h2>Boek een kampeer- of camperplaats</h2>
        <p>Boek een plaats en check direct in. U betaald vooraf de nachten die u boekt. Bij eerder vertrek vindt geen restitutie plaats.</p>
        <p>De slagboom werkt op kentekenherkenning.</p>
      `,
      de: `
        <h2>Campingplatz oder Wohnmobilstellplatz buchen</h2>
        <p>Reservieren Sie einen Platz und checken Sie sofort ein. Sie zahlen im Voraus für die von Ihnen gebuchten Nächte. Bei vorzeitiger Abreise gibt es keine Rückerstattung.</p>
        <p>Die Schranke funktioniert bei der Nummernschilderkennung.</p>`,
      en: `
        <h2>Book a camping pitch or motorhome pitch</h2>
        <p>Book a seat and check in immediately. You pay in advance for the nights you book. In case of early departure there will be no refund.</p>
        <p>The barrier works on license plate recognition.</p>
      `,
      // todo: translate
      fr: '',
    },
  },
  borkerheide: {
    'accommodation-title': {
      nl: 'Kies een type',
      de: 'Wählen Sie die Art Ihres Stellplatzes',
      en: 'Choose a type',
      // todo: translate
      fr: '',
    },
    'available-results': {
      nl: 'Beschikbaar',
      de: 'Verfügbare Optionen',
      en: 'Available at',
      // todo: translate
      fr: '',
    },
    'floorplan-title': {
      nl: 'Plattegrond',
      de: 'Lageplan',
      fr: 'Carte',
      en: 'Map',
    },
    // no finish-text-2 for borkerheide
    'finish-text-2': {
      ...noTranslation,
    },
    'intro-booking': {
      nl: 'Start boeking',
      de: 'Buchung starten',
      en: 'Start Booking',
      fr: 'Commencer la réservation',
    },
    'intro-text-1': {
      nl: `
        <h2>Boek een kampeer- of camperplaats.</h2>
        <p>U kunt hier een kampeer- of camperplaats boeken en direct inchecken. Met een camper kunt u ook op een kampeerplaats staan. U kunt gelijk betalen met de pin, of later via de mail. U ontvangt dan een betaal-link via Ideal.</p>
      `,
      de: `
        <h2>Buchen Sie einen Zelt-, Wohnwagen-, oder Wohnmobil-Stellplatz.</h2>
        <p>Sie können hier einen Stellplatz buchen und direkt einchecken. Mit Ihrem Wohnmobil dürfen Sie sich auch auf einen Zelt-, oder Wohnwagenstellplatz stellen. Sie können sofort mit EC Karte oder später über Mail bezahlen - Sie bekommen dann einen Link zum Bezahlen per Ideal zugesendet.</p>
      `,
      en: `
        <h2>Book a camping- or motorhome pitch</h2>
        <p>You can book a camping- or motorhome pitch here and check in directly. You can pay with mobile banking or at a later time at the reception.</p>
        <p>Extensive information about our camping- and camper pitches can be found at the reception or check %website_url%</p>
    `,
      fr: ``,
    },
    'intro-text-2': {
      nl: `
      <p>De uitcheque tijd is 12.00 uur. Mocht u later willen vertrekken (alleen buiten het hoogseizoen) informeer dan bij de beheerder.</p>
      <p>Uitgebreide informatie over onze camping vindt u op %website_url%.</p>
    `,
      de: `<p>Sie müssen den Platz bis 12 Uhr verlassen. Falls sie erst später abreisen möchten (nur außerhalb der Hochsaison möglich), setzen Sie sich bitte mit dem Campingplatzbetreiber in Verbindung. Ausführliche Informationen über unseren Campingplatz finden Sie auf %website_url%.</p>`,
      en: `<p>Departure or check out time is 12.00 pm. If you want to check out later ( only possible in low season) please contact the manager at the reception.</p>`,
      fr: ``,
    },
    'finish-text-1': {
      nl: `
        <h2>Uw boeking is succesvol verlopen.</h2>
        <p>U kunt ook gebruik maken van ons WiFi- netwerk. Het wachtwoord is: Landgoed2020</p>
        <p>Mocht u vragen hebben kunt u ons bellen 0593 331546</p>
        <p>Voor overige informatie verwijzen wij naar onze website: www.landgoedborkerheide.nl</p>
        <p>Wij wensen u een aangenaam verblijf op Landgoed Börkerheide.</p>
    `,
      de: `<h2>Ihre Buchung war erfolgreich.</h2>
 <p>Sie können auch unser WiFi-Netzwerk nutzen. Das Passwort lautet: Estate2020</p>
 <p>Wenn Sie Fragen haben, können Sie uns unter 0593 331546 anrufen</p>
 <p>Für weitere Informationen verweisen wir auf unsere Website: www.landgoedborkerheide.nl.</p>
 <p>Wir wünschen Ihnen einen angenehmen Aufenthalt in Landgoed Börkerheide.</p>`,
      fr: `<h2>Votre réservation est réussie.</h2>
 <p>Vous pouvez également utiliser notre réseau WiFi. Le mot de passe est : Estate2020</p>
 <p>Si vous avez des questions, vous pouvez nous appeler au 0593 331546</p>
<p>Pour d'autres informations, nous vous renvoyons à notre site web : www.landgoedborkerheide.nl.</p>
 <p>Nous vous souhaitons un agréable séjour à Landgoed Börkerheide.</p>`,
      en: `<h2>Your booking has been successful.</h2>
 <p>You can also use our WiFi network. The password is: Estate2020</p>
<p>If you have any questions you can call us 0593 331546</p>
<p>For other information we refer to our website: www.landgoedborkerheide.nl.</p>
<p>We wish you a pleasant stay at Landgoed Börkerheide.</p>`,
    },
    'payment-later': {
      nl: 'Betalen kan met mobiel bankieren of op een later tijdstip bij de receptie.',
      de: 'Die Zahlung kann per online Banking (oder Sie bekommen eine Mail mit einem link, um über  IDEAL zu bezahlen)',
      fr: `Le paiement peut être effectué par la banque mobile ou à une heure ultérieure à la réception.`,
      en: `Payment can be made by mobile banking or at a later time at reception.`,
    },
    salution: {
      nl: 'Aanhef',
      de: 'Anrede',
      fr: 'Salutation',
      en: 'Titel',
    },
  },
  reestland: {
    'confirm-title': {
      nl: '',
      de: '',
      en: '',
      fr: '',
    },
  },
  kostverloren: {
    'intro-text-1': {
      nl: `<h2>Boek een Camperplaats</h2>
           <p>U kunt hier een camperplaats boeken en direct toepassen. Betalen kan met uw pin.</p>
           <p>Uitgebreide informatie over onze camperplaatsen vind u bij de receptie op de camping of check %website_url%</p>`,
    },
    'intro-text-2': {
      nl: `<h3>Accommodatie boeken</h3>
           <p>Wilt u reserveren met een caravan dan meld u zich bij de receptie of u boekt telefonisch of online via %website_url%.</p>`,
    },
    'payment-later': {
      nl: `U kunt nu betalen met pin of voor contant melden bij receptie.`
    },
    'nav-guide': {
      nl: `Loop je tegen een probleem aan? Meld je dan bij de receptie of bel als de receptie is gesloten met nummer 06-21390264`
    },
    'finish-text-1': {
      nl: `<h2>Boeking succesvol afgerond</h2>
            <p>De boeking is afgerond en u bent ingecheckt op locatie <strong class="location">%accommodation_name% (%location_name%)</strong>. U ontvangt binnen enkele minuten een bevestiging per mail. U betaald ter plaatse met pin of contant bij de receptie.</p>
            <p>Als u toch langer wilt blijven dan kan u op de datum van vertrek nogmaals een boeking maken en betalen.</p>`,
    },
    'finish-text-2': {
      nl: '<h1 class="xl">Uw vakantie op %park_name% is begonnen!</h1>',
    },
  },
  camperparkzeeland: {
    'extras-title': {
      nl: "Wilt u stroom aan uw boeking toevoegen?",
      de: 'Wollen Sie Strom zu Ihrer Buchung hinzufügen?',
      fr: 'Voulez-vous ajouter de l\'électricité à votre réservation?',
      en: 'Do you want to add electricity to your booking?',
      it: 'Vuoi aggiungere l\'elettricità alla tua prenotazione?',
      pt: 'Quer adicionar eletricidade à sua reserva?',
      es: '¿Quieres añadir electricidad a tu reserva?',
    },
    'available-results-none': {
      nl: 'Nee, Vol',
      de: 'Nein, Voll',
      fr: 'Non, complet',
      en: 'No, Full',
      it: 'No, Pieno',
      pt: 'Não, Completo',
      es: 'No, Completo',
    },
    'intro-text-1': {
      nl: `<h2>Boek direct een camperplaats</h2>
           <p>Na het invoeren van uw gegevens kunt u betalen met uw pinpas. Hierna bent u automatisch ingecheckt en kunt u binnenrijden.</p>
           <p>Let op! Wij vragen hiervoor om het kenteken van uw camper.</p>`,
      de: `<h2>Buchen Sie direkt einen Wohnmobilstellplatz</h2>
           <p>Nachdem Sie Ihre Daten eingegeben haben können Sie bezahlen mit Ihrer Bankkarte. Sie werden dann automatisch eingecheckt und können sofort einfahren.</p>
           <p>Beachten Sie bitte, dass wir dafür das Kennzeichen Ihres Wohnmobiles brauchen.</p>`,
      en: `<h2>Book a camper spot directly and pay with your bank card</h2>
           <p>After entering your registration data, you will be automatically checked in, and will have direct access to the camperpark.</p>
           <p>Please note that the license plate number of your camper will be requested.</p>`,
      fr: `<h2>Réservez ici un emplacement de camping-car</h2>
           <p>Après avoir saisi vos coordonnées, vous pouvez payer avec votre carte bancaire. Vous serez ensuite automatiquement enregistré et pourrez entrer avec votre véhicule.</p>
           <p>Veuillez noter que nous vous demanderons le numéro d'immatriculation de votre camping-car.</p>`,
    },
    'finish-text-1': {
      nl: `
        <h2>De boeking is bijna afgerond</h2>
        <p>Het te betalen bedrag kunt nu voldoen op de pinterminal.</p>
        <p>Na de betaling ontvangt u de bevestiging per mail.</p>
    `,
      de: `
        <h2>Die Buchung ist fast abgeschlossen</h2>
        <p>Der zu zahlende Betrag kann nun am Pinterminal beglichen werden.</p>
        <p>Nach der Zahlung erhalten Sie die Bestätigung per E-Mail.</p>
    `,
      en: `
        <h2>The booking is almost completed</h2>
        <p>You can now pay the amount due at the pinterminal.</p>
        <p>After the payment you will receive the confirmation by mail.</p>
    `,
    },
    'payment-later': {
      nl: '',
      de: '',
      en: '',
    },
    'nav-guide': {
      nl: 'Loop je tegen een probleem aan? Bel 31 (0)118 700 225',
      de: 'Haben Sie ein Problem? Ruf 31 (0)118 700 225',
      en: 'Running into a problem? Call 31 (0)118 700 225',
      fr: 'Vous rencontrez un problème ? Appelez le 0031 118 700 225',
    },
    'intro-text-2': {
      nl: ``,
      de: ``,
      en: ``,
      fr: ``,
    },
    'floorplan-choose-location': {
      nl: 'Selecteer een locatie.',
      de: 'Wählen Sie Ihren Platz.',
      fr: "Sélectionnez un emplacement.",
      en: 'Select a location.',
    },
    'nav-quit': {
      nl: 'Stoppen en opnieuw beginnen',
      de: 'Anhalten und neu starten',
      fr: 'Annuler et recommencer',
      en: 'Stop and start again',
    },
    'travelgroup-title': {
      nl: 'Reisgezelschap',
      de: 'Reisegesellschaft',
      fr: 'Nombre de participants séjournant',
      en: 'Travel company',
    },
    'available-results': {
      nl: 'Beschikbaar',
      de: 'Verfügbar unter',
      fr: "Disponible",
      en: 'Available at',
    },
    title: {
      nl: 'Boeken',
      de: 'Buchen',
      fr: 'Votre réservation',
      en: 'Book',
    },
    'floorplan-title': {
      nl: 'Kies uw locatie',
      de: 'Wählen Sie Ihren Standort',
      fr: 'Choisissez votre emplacement',
      en: 'Choose your location',
    },
    'stay-cost': {
      nl: 'Verblijfskosten',
      de: 'Kosten für Unterkunft und Verpflegung',
      fr: "Total de votre séjour",
      en: 'Accommodation costs',
    },
    'personaldata-title': {
      nl: 'Persoonsgegevens',
      de: 'Persönliche Daten',
      fr: 'Vos données',
      en: 'Personal information',
    },
    salution: {
      nl: 'Aanhef',
      de: 'Anrede',
      fr: 'Intitulé',
      en: 'Salutation',
    },
    'confirm-title': {
      nl: 'Bevestigen',
      de: 'Bestätigen',
      fr: 'Confirmation',
      en: 'Confirm',
    },
    'btn-complete': {
      nl: 'Boeking bevestigen',
      de: 'Buchung bestätigen',
      fr: 'Confirmez la réservation',
      en: 'Confirm booking',
    },
    'check-details': {
      nl: 'Controle gegevens',
      de: 'Bitte prüfen Sie Ihre Buchung',
      fr: 'Vérification des données',
      en: 'Control data',
    },
    'pin-title': {
      nl: 'Pinnen',
      de: 'Zahlen',
      fr: 'Paiement',
      en: 'Pay',
    },
    'pin-description': {
      nl: `
      <p>Binnen enkele seconden kunt u uw betaling op de betaalautomaat voldoen met uw pinpas</p>
      <p>U heeft 30 seconden om de betaling te voldoen, lukt dit niet?</p>
      <p>Wij versturen u nog altijd een betaallink per mail, zodat u alsnog de betaling kan voldoen</p>
    `,
      de: `
      <p>Innerhalb von Sekunden können Sie Ihre Zahlung am Zahlungsterminal mit Ihrer Debitkarte vornehmen</p>
      <p>Sie haben 30 Sekunden, um die Zahlung zu tätigen, hat es nicht geklappt? </p>
      <p>Wir senden Ihnen nach wie vor einen Zahlungslink per E-Mail zu, so dass Sie die Zahlung noch vornehmen können</p>.
    `,
      fr: `
      <p>En quelques secondes, vous pouvez effectuer votre paiement au terminal de point de vente avec votre carte bancaire.</p>
      <p>Vous disposez de 30 secondes pour effectuer le paiement. Vous n'avez pas réussi ?</p>
      <p>Nous vous enverrons quand même un lien de paiement par e-mail pour que vous puissiez effectuer le paiement.</p>.
    `,
      en: `
      <p>Within seconds you can make your payment at the POS terminal with your debit card</p>
      <p>You have 30 seconds to make the payment, it didn't work? </p>
      <p>We still send you a payment link by email, so that you can still make the payment</p>
    `,
    },
  },
  lutjekossink: {
    'intro-text-1': {
      nl: `<h2>Boek een kampeerplek</h2>
      <p>Je kunt hier een kampeer- of camperplaats boeken en direct inchecken.</p>
      <p>Uitgebreide informatie over onze kampeer- en camper plaatsen vind je de receptie of check %website_url%</p>
    `,
      de: `<h2>Buchen Sie einen Stellplatz</h2>
      <p>Sie können hier einen Camping- oder Wohnmobilstellplatz buchen und direkt einchecken.</p>
      <p>Umfassende Informationen über unsere Camping- und Wohnmobilstellplätze finden Sie an der Rezeption oder unter %website_url%</p>
    `,
      en: `<h2>Book a camping spot</h2>
      <p>You can book a camping- or motorhome pitch here and check in directly.</p>
      <p>Comprehensive information about our camping- and camper pitches can be found at the reception or check %website_url%</p>
    `,
    },
    'payment-later': {
      nl: 'Betaal zo dadelijk gelijk met het pinapparaat naast de boekingszuil. Bevestig eerst uw boeking.',
      de: 'Zahlen Sie gleich mit dem Kartenleser neben dem Buchungsterminal. Bestätigen Sie zuerst Ihre Buchung.',
      fr: 'Payez immédiatement avec le terminal de paiement à côté de la borne de réservation. Confirmez d\'abord votre réservation.',
      en: 'Pay right away using the pin machine next to the booking kiosk. Please confirm your booking first.',
      it: 'Pagate subito con il bancomat accanto al chiosco di prenotazione. Confermare prima la prenotazione.',
      pt: 'Pague imediatamente com o terminal de pagamento ao lado do quiosque de reserva. Por favor, confirme sua reserva primeiro.',
      es: 'Pague de inmediato con la máquina de tarjetas junto al quiosco de reserva. Confirme su reserva primero.',
    },
    'finish-text-1': {
      nl: `
        <h2>Boeking succesvol afgerond</h2>
        <p>U dient nu alleen nog het bedrag te pinnen op het pinapparaat dat direct naast de boekingszuil staat.</p>
        <p>Het bedrag van uw boeking staat al voor u klaar op het pinapparaat. GRAAG NU PINNEN.</p>
        <p>Kampeerplek kiest u uit in overleg met eigenaar van de camping</p>
    `,
      de: `
        <h2>Buchung erfolgreich abgeschlossen</h2>
        <p>Sie müssen jetzt nur noch den Betrag am Kartenleser bezahlen, der direkt neben dem Buchungsterminal steht.</p>
        <p>Der Betrag Ihrer Buchung steht bereits auf dem Kartenleser für Sie bereit. BITTE JETZT ZAHLEN.</p>
        <p>Wählen Sie Ihren Campingplatz in Absprache mit dem Eigentümer des Campingplatzes</p>        
    `,
      fr: `
        <h2>Réservation effectuée avec succès</h2>
        <p>Vous devez maintenant seulement payer le montant sur le terminal de paiement situé juste à côté du terminal de réservation.</p>
        <p>Le montant de votre réservation est déjà prêt pour vous sur le terminal de paiement. S'IL VOUS PLAÎT, PINEZ MAINTENANT.</p>
        <p>Choisissez votre emplacement de camping en accord avec le propriétaire du camping</p>
    `,
      en: `
        <h2>Booking successfully completed</h2>
        <p>You now only need to pay the amount on the card reader located right next to the booking terminal.</p>
        <p>The amount of your booking is already ready for you on the card reader. PLEASE PAY NOW.</p>
        <p>Choose your camping spot in consultation with the owner of the campsite</p>
    `,
      it: `
        <h2>Prenotazione completata con successo</h2>
        <p>Devi solo pagare l'importo sul lettore di carte situato proprio accanto al terminale di prenotazione.</p>
        <p>L'importo della tua prenotazione è già pronto per te sul lettore di carte. PER FAVORE PAGA ORA.</p>
        <p>Scegli il tuo posto campeggio in consultazione con il proprietario del campeggio</p>
    `,
      pt: `
        <h2>Reserva concluída com sucesso</h2>
        <p>Agora você só precisa pagar o valor no leitor de cartões localizado ao lado do terminal de reserva.</p>
        <p>O valor da sua reserva já está pronto para você no leitor de cartões. POR FAVOR, PAGUE AGORA.</p>
        <p>Escolha o seu local de acampamento em consulta com o proprietário do parque de campismo</p>
    `,
      es: `
        <h2>Reserva completada con éxito</h2>
        <p>Ahora solo necesita pagar la cantidad en el lector de tarjetas ubicado justo al lado del terminal de reserva.</p>
        <p>La cantidad de su reserva ya está lista para usted en el lector de tarjetas. POR FAVOR, PAGUE AHORA.</p>
        <p>Elija su lugar de acampada en consulta con el propietario del camping</p>
    `,
    },
    'finish-text-2': {
      nl: '<h1 class="xl">GENIET VAN UW VERBLIJF OP NATUURKAMPEERTERREIN LUTJE KOSSINK!</h1>',
      de: '<h1 class="xl">GENIESSEN SIE IHREN AUFENTHALT AUF DEM NATURCAMPINGPLATZ LUTJE KOSSINK!</h1>',
      fr: '<h1 class="xl">PROFITEZ DE VOTRE SÉJOUR AU CAMPING NATUREL LUTJE KOSSINK!</h1>',
      en: '<h1 class="xl">ENJOY YOUR STAY AT NATURAL CAMPSITE LUTJE KOSSINK!</h1>',
      it: '<h1 class="xl">GODITI IL TUO SOGGIORNO AL CAMPEGGIO NATURALE LUTJE KOSSINK!</h1>',
      pt: '<h1 class="xl">APROVEITE A SUA ESTADIA NO CAMPISMO NATURAL LUTJE KOSSINK!</h1>',
      es: '<h1 class="xl">¡DISFRUTA DE TU ESTANCIA EN EL CAMPING NATURAL LUTJE KOSSINK!</h1>',
    },
  },
  zeehoeve: {
    'payment-later': {
      nl: 'Na het voldoen van de betaling kunt u de camping op.',
      de: 'Nach der Zahlung können Sie den Campingplatz betreten.',
      en: 'After making the payment you can enter the campsite.',
    }
  },
  biesthoutakker: {
    'intro-text-1': {
      nl: `
      <h2>Boek een camperplaats</h2>
      <p>Je kunt hier een camperplaats boeken en direct inchecken. Betalen dient, bij voltooien van de boeking, direct voldaan te worden met mobiel bankieren. Gelieve uw betaalpas bij de hand te houden.</p>    
    `,
      de: `
      <h2>Wohnmobilstellplatz buchen</h2>
      <p>Sie können hier einen Wohnmobilstellplatz buchen und direkt einchecken. Die Bezahlung erfolgt nach Abschluss der Buchung direkt über das mobile Banking. Bitte halten Sie Ihre Debitkarte bereit.</p>
    `,
      fr: `
      <h2>Réserver un emplacement de camping-car</h2>
      <p>Vous pouvez réserver un emplacement de mobilhome ici et vous enregistrer directement. Le paiement doit être effectué directement après la confirmation de la réservation. Assurez-vous d'avoir votre banque mobile à portée de main.</p>
    `,
      en: `
      <h2>Book a mobilhome pitch</h2>
      <p>You can book a mobilhome pitch here and check in direclty. Payment must be made direclty after confirmation of the booking. Make sure you have your mobile banking ready.</p>
    `,
    },
    'intro-text-2': {
      nl: ``,
      de: ``,
      fr: ``,
      en: ``,
    },
    'nav-guide': {
      nl: `Loop je tegen een probleem aan? Bel dan naar het nummer %phone_number%`,
      de: `Haben Sie ein Problem? Bitte rufen Sie %phone_number% an`,
      fr: `Vous rencontrez un problème ? Veuillez appeler %phone_number%`,
      en: `Running into a problem? Please call the number %phone_number%`,
    }
  },
  maarnseberg: {
    'intro-text-1-alt': {
      nl: `<p>Welkom als dagbezoeker op De Maarnse Berg. Je kan hier het voertuig registreren waarmee 
je toegang tot het park wilt verkrijgen. Dit is alleen mogelijk op de dag zelf.  Betalen kan 
uitsluitend met mobiel bankieren.</p>
<p>Inrijden als bezoeker is uitsluitend mogelijk tussen 07.00 – 21.30, uitrijden voor 23.59 op de 
dag van aanschaf.</p> 
<p>Op De Maarnse Berg zijn geen huisdieren toegestaan, ook niet van bezoekers.</p> 
<p>Wil je vandaag komen overnachten op een kampeer- of camperplaats kies dan in het 
keuzemenu voor de mogelijkheid “boek een kampeerplaats”. Uitgebreide informatie over de 
kampeerplaatsen en bungalows is te vinden op de website: 
%website_url%. Hier vind 
je ook de beschikbaarheid voor toekomstige reserveringen.</p> 
    `,
      de: `<p>Welkom als dagbezoeker op De Maarnse Berg. Je kan hier het voertuig registreren waarmee 
je toegang tot het park wilt verkrijgen. Dit is alleen mogelijk op de dag zelf.  Betalen kan 
uitsluitend met mobiel bankieren.</p>
<p>Inrijden als bezoeker is uitsluitend mogelijk tussen 07.00 – 21.30, uitrijden voor 23.59 op de 
dag van aanschaf.</p> 
<p>Op De Maarnse Berg zijn geen huisdieren toegestaan, ook niet van bezoekers.</p> 
<p>Wil je vandaag komen overnachten op een kampeer- of camperplaats kies dan in het 
keuzemenu voor de mogelijkheid “boek een kampeerplaats”. Uitgebreide informatie over de 
kampeerplaatsen en bungalows is te vinden op de website: 
%website_url%. Hier vind 
je ook de beschikbaarheid voor toekomstige reserveringen.</p> 
    `,
      fr: `<p>Welkom als dagbezoeker op De Maarnse Berg. Je kan hier het voertuig registreren waarmee 
je toegang tot het park wilt verkrijgen. Dit is alleen mogelijk op de dag zelf.  Betalen kan 
uitsluitend met mobiel bankieren.</p>
<p>Inrijden als bezoeker is uitsluitend mogelijk tussen 07.00 – 21.30, uitrijden voor 23.59 op de 
dag van aanschaf.</p> 
<p>Op De Maarnse Berg zijn geen huisdieren toegestaan, ook niet van bezoekers.</p> 
<p>Wil je vandaag komen overnachten op een kampeer- of camperplaats kies dan in het 
keuzemenu voor de mogelijkheid “boek een kampeerplaats”. Uitgebreide informatie over de 
kampeerplaatsen en bungalows is te vinden op de website: 
%website_url%. Hier vind 
je ook de beschikbaarheid voor toekomstige reserveringen.</p> 
    `,
      en: `<p>Welcome as a day visitor to De Maarnse Berg. Here you can register the vehicle with which you wish to gain access to the park. This is only possible on the day itself. Payment can only be done by mobile banking.</p>
<p>Entry as a visitor is only possible between 07.00 - 21.30, exit before 23.59 on the day of purchase.</p>
<p>No pets are allowed on De Maarnse Berg, including those of visitors.</p>
<p>If you would like to stay overnight on a camping or motorhome pitch today, please choose the option "book a camping pitch" in the selection menu. Detailed information about the camping pitches and bungalows can be found on the website: %website_url%. Here you will also find availability for future reservations.</p> `,
    },
    'intro-text-2-alt': {
      nl: '',
      de: '',
      fr: '',
      en: '',
    },
    'payment-later': {
      nl: 'Betalen kan via de pinautomaat op de zuil.',
      de: 'Betalen kan via de pinautomaat op de zuil.',
      fr: 'Betalen kan via de pinautomaat op de zuil.',
      en: `Payment can be made by mobile banking.`,
    },
  },
  camperparkamsterdam: {
    'intro-text-1': {
      nl: `
        <h2>Welkom bij Camperpark Amsterdam!</h2>
        <p>Leuk dat je bij ons wilt verblijven.</p>
        <p>Start je aanmelding in 3 stappen:</p>
        <ol>
            <li>Vul je gegevens in en kies het type accommodatie.</li> 
            <li>Betaal bij de balie van onze receptie</li> 
            <li>Je boeking is compleet en je verblijf kan beginnen!</li>         
        </ol>
      `,
      de: `
        <h2>Willkommen im Camperpark Amsterdam!</h2>
        <p>Schön, dass Sie bei uns bleiben möchten.</p>
        <p>Beginnen Sie Ihre Anmeldung in 3 Schritten:</p>
        <ol>
            <li>Füllen Sie Ihre Daten aus und wählen Sie die Art der Unterkunft.</li> 
            <li>Bezahlen Sie an unserer Rezeption.</li> 
            <li>Ihre Buchung ist abgeschlossen und Ihr Aufenthalt kann beginnen!</li>
        </ol>`,
      en: `
        <h2>Welcome to Camperpark Amsterdam!</h2>
        <p>Nice that you want to stay with us.</p>
        <p>Start your registration in 3 steps:</p>
        <ol>
            <li>Fill in your details and choose the type of accommodation.</li> 
            <li>Pay at our reception desk.</li> 
            <li>Your booking is complete and your stay can begin!</li>
        </ol>
      `,
      fr: `
        <h2>Bienvenue au Camperpark Amsterdam !</h2>
        <p>Nous sommes ravis que tu veuilles rester avec nous.</p>
        <p>Commencez votre inscription en 3 étapes :</p>
        <ol>
            <li>Remplissez vos coordonnées et choisissez le type de logement. </li> 
            <li>Payez à notre réception.</li> 
            <li>Votre réservation est terminée et votre séjour peut commencer !</li>
        </ol>
      `,
      it: `
        <h2>Benvenuti al Camperpark Amsterdam!</h2>
        <p>È bello che tu voglia soggiornare da noi.</p>
        <p>Inizia la tua registrazione in 3 passi:</p>
        <ol>
            <li>Inserite i vostri dati e scegliete il tipo di alloggio. </li> 
            <li>Pagare alla nostra reception.</li> 
            <li>La prenotazione è completa e il soggiorno può iniziare!</li>
        </ol>
      `,
      pt: `
        <h2>¡Bienvenido a Camperpark Amsterdam!</h2>
        <p>Me alegro de que quieras alojarte con nosotros.</p>
        <p>Comience su registro en 3 pasos:</p>
        <ol>
            <li>Rellena tus datos y elige el tipo de alojamiento. </li> 
            <li>Paga en nuestra recepción.</li> 
            <li>Su reserva está completa y su estancia puede comenzar.</li>
        </ol>
      `,
      es: `
        <h2>¡Bienvenido a Camperpark Amsterdam!</h2>
        <p>Me alegro de que quieras alojarte con nosotros.</p>
        <p>Comience su registro en 3 pasos:</p>
        <ol>
            <li>Rellena tus datos y elige el tipo de alojamiento. </li> 
            <li>Paga en nuestra recepción.</li> 
            <li>Su reserva está completa y su estancia puede comenzar.</li>
        </ol>
      `,
    },
    'intro-text-2': {
      nl: `Uitgebreide informatie over onze kampeer-, camper-, of caravanplaatsen vind je bij de receptie of op https://camperparkamsterdam.nl/accommodaties.`,
      en: `Detailed information about our camping, campervan or caravan sites can be found at reception or at  https://camperparkamsterdam.nl/accommodaties.`,
      de: `Ausführliche Informationen über unsere Camping-, Wohnmobil- oder Wohnwagenplätze erhalten Sie an der Rezeption oder unter https://camperparkamsterdam.nl/accommodaties.`,
      fr: `Des informations détaillées sur nos terrains de camping, de camping-car ou de caravane sont disponibles à la réception ou sur https://camperparkamsterdam.nl/accommodaties.`,
      it: `Informazioni dettagliate sui nostri campeggi, aree per camper o caravan sono disponibili alla reception o su https://camperparkamsterdam.nl/accommodaties.`,
      pt: `Informazioni dettagliate sui nostri campeggi, aree per camper o caravan sono disponibili alla reception o su https://camperparkamsterdam.nl/accommodaties.`,
      es: `Encontrará información detallada sobre nuestros campings, campers o caravanas en la recepción o en https://camperparkamsterdam.nl/accommodaties.`,
    },
    'intro-booking': {
      nl: 'Start',
      de: 'Start',
      en: 'Start',
      fr: 'Démarrer',
      es: 'Iniciar',
      it: 'Iniziare',
      pt: 'Começar',
    },
    'intro-text-1-alt': {
      nl: `<p>Meld hier uw bestaande boeking aan.</p>`,
      de: `<p>Melden Sie hier Ihre bestehende Buchung an.</p>`,
      fr: `<p>Signalez ici votre réservation existante.</p>`,
      en: `<p>Report your existing booking here.</p>`,
      it: `<p>Segnala qui la tua prenotazione esistente.</p>`,
      pt: `<p>Informe aqui a sua reserva existente.</p>`,
      es: `<p>Informe aquí su reserva existente.</p>`,
    },
    'title': {
      nl: `Aanmelden`,
      de: `Anmelden`,
      fr: `S'inscrire`,
      en: `Register `,
      it: `Iscriviti`,
      pt: `Registar`,
      es: `Registrarse`,
    },
    'timeout-text': {
      nl: 'Je hebt deze zuil 5 minuten niet gebruikt. Over 30 seconden breken we je aanmelding af. Maak een keuze:',
      de:
          'Sie haben diese Spalte seit 5 Minuten nicht mehr benutzt. Wir werden Ihre Anmeldung in 30 Sekunden abbrechen. Treffen Sie eine Wahl:',
      fr:
          "Vous n'avez pas utilisé cette rubrique depuis 5 minutes. Nous annulerons votre inscription dans 30 secondes. Faites un choix :",
      en: "You haven't used this terminal for 5 minutes. We'll cancel your registration in 30 seconds. Make a choice:",
      it: "Non hai utilizzato questo terminale per 5 minuti. Annulleremo la tua registrazione tra 30 secondi. Fai una scelta:",
      pt: 'Não usaste este terminal durante 5 minutos. Vamos cancelar a tua inscrição em 30 segundos. Faz uma escolha:',
      es: 'No has utilizado este terminal durante 5 minutos. Cancelaremos tu registro en 30 segundos. Haz una elección:',
    },
    'finish-text-1': {
      nl: `
        <h2>Bedankt voor je aanmelding!</h2>
        <p>Loop naar de receptiebalie om direct te betalen.Je ontvangt daar een unieke barcode kaart om het park op te rijden. </p>
        <p>Het begin van jouw vakantie bij Camperpark Amsterdam: The best way to stay!</p>`,
      de: `
        <h2>Vielen Dank für Ihre Anmeldung!</h2>
        <p>Gehen Sie zur Rezeption, um direkt zu bezahlen. Dort erhalten Sie eine einmalige Barcodekarte, mit der Sie den Park betreten können.</p>
        <p>Der Beginn Ihres Urlaubs im Camperpark Amsterdam: The best way to stay!</p>`,
      en: `
        <h2>Thank you for your registration!</h2>
        <p>Walk to the reception desk to pay directly. There you will receive a unique barcode card to enter the park.</p>
        <p>The start of your holiday at Camperpark Amsterdam: The best way to stay!</p>`,
      fr: `
        <h2>Merci pour votre inscription !</h2>
        <p>Dirigez-vous vers le bureau d'accueil pour payer directement. Vous y recevrez une carte à code-barres unique pour entrer dans le parc. </p>
        <p>Le début de vos vacances au Camperpark Amsterdam: The best way to stay!</p>`,
      es: `
        <h2>Gracias por su inscripción.</h2>
        <p>Diríjase al mostrador de recepción para pagar directamente. Allí recibirá una tarjeta con un código de barras único para entrar en el parque.</p>
        <p>El comienzo de sus vacaciones en Camperpark Amsterdam: The best way to stay!</p>`,
      pt: `
        <h2>Gracias por su inscripción.</h2>
        <p>Diríjase al mostrador de recepción para pagar directamente. Allí recibirá una tarjeta con un código de barras único para entrar en el parque.</p>
        <p>El comienzo de sus vacaciones en Camperpark Amsterdam: The best way to stay!</p>`,
      it: `
        <h2>Grazie per la vostra registrazione!</h2>
        <p>Andate alla reception per pagare direttamente. Lì riceverete una tessera con codice a barre unica per entrare nel parco.</p>
        <p>L'inizio della vostra vacanza al Camperpark Amsterdam: The best way to stay!</p>`,
    }
  },
  camperparkamsterdam2: {
    'title': {
      nl: `Aanmelden`,
      de: `Anmelden`,
      fr: `S'inscrire`,
      en: `Register `,
      it: `Iscriviti`,
      pt: `Registar`,
      es: `Registrarse`,
    },
    'payment-later': {
      nl: ``,
      de: ``,
      fr: ``,
      en: ``,
      it: ``,
      pt: ``,
      es: ``,
    },
    'finish-text-1': {
      nl: `U kunt zich melden aan de balie om uw boeking definitief af te ronden`,
      de: `Sie können sich am Schalter melden, um Ihre Buchung abzuschließen`,
      fr: `Vous pouvez vous présenter au guichet pour finaliser votre réservation.`,
      en: `You can report to the front desk to finalize your booking`,
      it: `È possibile presentarsi allo sportello per finalizzare la prenotazione`,
      pt: `Pode dirigir-se ao balcão para finalizar a sua reserva`,
      es: `Puede presentarse en el mostrador para finalizar su reserva`,
    },
    'finish-text-2': {
      nl: ``,
      de: ``,
      fr: ``,
      en: ``,
      it: ``,
      pt: ``,
      es: ``,
    }
  },
  viermannekesbrug: {
    'intro-text-1': {
      nl: `
      <h2>Boek een camperplaats</h2>
      <p>Je kunt hier een camperplaats boeken en direct inchecken. Betalen kan direct aan deze boekingszuil via de pinterminal</p>
      <p>Uitgebreide informatie over onze camperplaatsen vind je op onze website %website_url%</p>
    `,
      de: `
      <h2>Wohnmobilstellplatz buchen</h2>
      <p>Sie können hier einen Wohnmobilstellplatz buchen und direkt einchecken. Sie können direkt an diesem Kiosk bezahlen</p>
      <p>Umfassende Informationen über unsere Wohnmobilstellplatze finden Sie unter %website_url%</p>
    `,
      fr: `
      <h2>Réserver un emplacement de camping</h2>
      <p>Vous pouvez réserver un emplacement de camping ici et vous enregistrer directement. Vous pouvez payer directement à ce terminal de réservation via le terminal de paiement</p>
      <p>Vous trouverez des informations complètes sur nos emplacements de camping sur notre site Web %website_url%</p>
    `,
      en: `
      <h2>Book a camping pitch</h2>
      <p>You can book a camping pitch here and check in directly. You can pay directly at this kiosk</p>
      <p>Comprehensive information about our motorhome pitches can be found at our website %website_url%</p>
    `,
      it: `
        <h2>Prenota un posto campeggio o camper</h2>
        <p>Puoi prenotare un posto campeggio o camper qui e fare il check-in direttamente. Puoi pagare con mobile banking o in un secondo momento alla reception.</p>
        <p>Troverai informazioni dettagliate sui nostri posti campeggio e camper alla reception o controlla %website_url%</p>
        `,
      pt: `
        <h2>Reserve um lugar de acampamento ou de autocaravana</h2>
        <p>Pode reservar um lugar de acampamento ou de autocaravana aqui e fazer o check-in diretamente. Pode pagar com mobile banking ou mais tarde na receção.</p>
        <p>Encontrará informações detalhadas sobre os nossos lugares de acampamento e de autocaravana na receção ou verifique %website_url%</p>
        `,
      es: `
        <h2>Reserve un lugar de camping o de autocaravana</h2>
        <p>Puede reservar un lugar de camping o de autocaravana aquí y registrarse directamente. Puede pagar con banca móvil o más tarde en recepción.</p>
        <p>Encontrará información detallada sobre nuestros lugares de camping y de autocaravana en recepción o consulte %website_url%</p>
        `,
    },
    'intro-text-2': {
      nl: '',
      de: '',
      fr: '',
      en: '',
      it: '',
      pt: '',
      es: '',
    },
    'nav-guide': {
      nl: 'Loop je tegen een probleem aan? Bel %phone_number%.',
      de: 'Haben Sie ein Problem? Dann rufen Sie an %phone_number%.',
      fr: 'Vous avez un problème ? Appelez %phone_number%.',
      en: 'Do you have a problem? Call %phone_number%.',
      it: 'Hai un problema? Allora segnalalo alla reception o chiama il %phone_number% quando la reception è chiusa.',
      pt: 'Tem um problema? Então reporte-o à receção ou ligue para %phone_number% quando a receção estiver fechada.',
      es: '¿Tienes un problema? Entonces repórtalo a recepción o llama al %phone_number% cuando recepción esté cerrada.',
    },
  }
};

module.exports = overrideTranslations;
